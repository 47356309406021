// TODO: clear this up as soon as possible
/* eslint-disable no-restricted-imports */
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { createTracker } from 'redux-segment';
import { thunk } from 'redux-thunk';
import undoable from 'redux-undo';

import { ownersApi } from 'pages/layout/app/api/ownersApi';
import { ownersApi as ownersApi2 } from 'pages/my-properties/app/api/ownersApi';
import { listingsApi } from 'pages/my-properties/app/api/listingsApi';
import listings from 'pages/my-properties/app/stores/listings.slice';
import { reservationsApi } from 'pages/my-properties/app/api/reservationsApi';
import { revenueAnalyticsApi } from 'pages/my-properties/app/api/revenueAnalyticsApi';
import { rasApi } from 'pages/my-properties/app/api/rasApi';
import userReducer from 'user/user.reducers';
import propertyReducer from 'pages/my-properties/app/stores/property.reducer';
import uiReducer from 'ui/ui.reducers';
import modalReducer from 'pages/my-properties/app/modal/redux/modal.reducer';

const tracker = createTracker();

const createRootReducer = (reducers) =>
  combineReducers({
    user: userReducer,
    property: propertyReducer,
    ui: uiReducer,
    modal: undoable(modalReducer, { limit: 4 }),
    [ownersApi.reducerPath]: ownersApi.reducer,
    [ownersApi2.reducerPath]: ownersApi2.reducer,
    [listingsApi.reducerPath]: listingsApi.reducer,
    [reservationsApi.reducerPath]: reservationsApi.reducer,
    [revenueAnalyticsApi.reducerPath]: revenueAnalyticsApi.reducer,
    [rasApi.reducerPath]: rasApi.reducer,
    listings,
    ...reducers,
  });

export default () => {
  const middleware = [
    thunk,
    tracker,
    ownersApi.middleware,
    ownersApi2.middleware,
    listingsApi.middleware,
    reservationsApi.middleware,
    revenueAnalyticsApi.middleware,
    rasApi.middleware,
  ];

  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'owner-portal',
      })) ||
    compose;
  const store = createStore(
    createRootReducer(),
    composeEnhancers(applyMiddleware(...middleware))
  );

  window.appStore = store;

  return store;
};

export const useAppDispatch = useDispatch;

export const useAppSelector = useSelector;
