import cn from 'classnames';

// import Alert from '@guestyci/foundation/Alert';
import { Col } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
// import t from '@guestyci/localize/t.macro';

// import { whoami } from 'user/user.services';

import PoweredBy from './components/PoweredBy';

const useStyles = createStyles(({ breakpoints: { down } }) => ({
  layout: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#f5f9ff',
  },
  header: {
    [down('sm')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  title: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  content: {
    padding: '73px 30px 0px 30px', // 58px navbar height + 15px padding
    flexGrow: 1,
    overflow: 'auto',
    [down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  contentInner: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
}));

const Header = ({ title, subTitle }) => {
  const classes = useStyles();
  // const user = whoami();

  return (
    <Col className={classes.header}>
      {/* {user.impersonator && (
        <Alert variant="other" disabled>
          {`${t('Notice')}: ${t(
            'You are currently logged in as {impersonator} and impersonating the owner',
            { impersonator: user.impersonator }
          )}`}
        </Alert>
      )} */}

      {title && (
        <TextField variant="h3" color="primary" bold className={classes.title}>
          {title}
        </TextField>
      )}

      {subTitle && (
        <TextField variant="h5" color="secondary">
          {subTitle}
        </TextField>
      )}
    </Col>
  );
};

const PageLayout = ({
  children,
  hasNavbar,
  title,
  subTitle,
  layoutContentClassName,
  layoutContentInnerClassName,
  isPoweredByVisible,
}) => {
  const classes = useStyles();

  return (
    <Col className={classes.layout}>
      <Col
        spacing={3}
        fullWidth
        className={cn([classes.content], {
          [layoutContentClassName]: layoutContentClassName,
        })}
      >
        {hasNavbar && <Header title={title} subTitle={subTitle} />}

        <Col
          fullWidth
          className={cn([classes.contentInner], {
            [layoutContentInnerClassName]: layoutContentInnerClassName,
          })}
        >
          {children}
          <PoweredBy isVisible={isPoweredByVisible} hasNavbar={hasNavbar} />
        </Col>
      </Col>
    </Col>
  );
};

export default PageLayout;
