import isUndefined from 'lodash/isUndefined';
import moment from 'moment';

import t from '@guestyci/localize/t.macro';
// has to alias intl so it does not get mocked by setupTests.js
import { intl as guestyIntl } from '@guestyci/localize';

export function getPriceStr(price, currency) {
  if (typeof price !== 'number') {
    return price;
  }

  return guestyIntl.formatNumber(price, {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export function pluralize(str, count) {
  return count > 1 ? `${str}s` : str;
}

export function getPercent(number) {
  if (typeof number !== 'number') {
    return number;
  }

  return Number(number / 100).toLocaleString(undefined, { style: 'percent' });
}

export const truncateWithEllipsis = (input, maxLength) =>
  input?.length > maxLength ? `${input.substring(0, maxLength)}...` : input;

export const numberToCharCode = (value) => {
  if (value === null || value === undefined) {
    return '';
  }

  if (value < 26) {
    return String.fromCharCode(65 + value);
  }

  return (
    numberToCharCode(Number(value / 26) - 1) +
    String.fromCharCode(65 + (value % 26))
  );
};

export const getMonthYearKey = (month, year) => `${month}_${year}`;

export const getCurrentMonthYearKey = () => {
  const d = new Date();
  return getMonthYearKey(d.getMonth(), d.getFullYear());
};

export const getDateFromKey = (key) => {
  const [month, year] = key.split('_');
  return new Date(year, month, 1);
};

export const getPrevMonthKey = (key) => {
  const date = getDateFromKey(key);
  const prevDate = new Date(date - 86400000);
  return getMonthYearKey(prevDate.getMonth(), prevDate.getFullYear());
};

export const getNextMonthKey = (key) => {
  const date = getDateFromKey(key);
  const month = date.getMonth();
  const year = date.getFullYear();
  const nextDate =
    month === 11 ? new Date(year + 1, 0, 1) : new Date(year, month + 1, 1);
  return getMonthYearKey(nextDate.getMonth(), nextDate.getFullYear());
};

export const toISODateString = (d) => {
  let month = d.getMonth() + 1;
  if (month < 10) month = `0${month}`;
  let date = d.getDate();
  if (date < 10) date = `0${date}`;
  return `${d.getFullYear()}-${month}-${date}`;
};

export const getLastDateInMonth = (month, year) => {
  return new Date(moment({ year, month, day: 1 }).endOf('month'));
};

export const getFromTo = (month, year) => {
  const from = toISODateString(new Date(year, month, 1));
  const to = toISODateString(getLastDateInMonth(month, year));
  return { from, to };
};

export const fromISODateString = (isoStr) => {
  const [year, month, date] = isoStr.split('-');
  return new Date(Number(year), Number(month) - 1, Number(date));
};

export function reducer(handlers, initialState = {}) {
  // eslint-disable-next-line default-param-last
  return (state = initialState, action) => {
    const handler = handlers[action.type];
    let result;

    if (handler) {
      result = handler(state, action);
    }

    if (isUndefined(result)) {
      return state;
    }
    return result;
  };
}

export const getMonths = () => [
  t('Jan'),
  t('Feb'),
  t('Mar'),
  t('Apr'),
  t('May'),
  t('Jun'),
  t('Jul'),
  t('Aug'),
  t('Sep'),
  t('Oct'),
  t('Nov'),
  t('Dec'),
];

const years = [];
const currYear = new Date().getFullYear();
for (let i = currYear; i >= 2014; i -= 1) years.push(i);

export const getYearsFrom2014 = () => years;

export const getYearsUntil2014 = (yearsInFuture = 0) =>
  [...Array(new Date().getFullYear() + yearsInFuture - 2013).keys()]
    .reverse()
    .map((y) => y + 2014);

export const sliceObject = (object, begin, end) => {
  const keys = Object.keys(object);
  const size = keys.length;
  const res = {};

  for (let i = begin; i < end && i < size; i += 1) {
    res[keys[i]] = object[keys[i]];
  }

  return res;
};

export const getActiveString = (isActive) =>
  isActive ? t('active') : t('inactive');

export const getReservationStatusString = (status) => {
  switch (status.toLowerCase()) {
    case 'inquiry':
      return t('inquiry');
    case 'alteration':
      return t('alteration');
    case 'completed':
      return t('completed');
    case 'checked_in':
      return t('checked in');
    case 'checked_out':
      return t('checked out');
    case 'closed':
      return t('closed');
    case 'declined':
      return t('declined');
    case 'expired':
      return t('expired');
    case 'awaiting_payment':
      return t('awaiting payment');
    case 'reserved':
      return t('reserved');
    case 'booked':
      return t('booked');
    case 'unavailable':
      return t('unavailable');
    case 'canceled':
      return t('canceled');
    case 'confirmed':
      return t('confirmed');

    default:
      return status;
  }
};

const statsFormatters = {
  revenue: getPriceStr,
  revPal: getPriceStr,
  avgNightlyRate: getPriceStr,
  accommodationFare: getPriceStr,
  occupancy: getPercent,
};

const defaultStatFormatter = (value) => value;

export const getFormatter = (name) =>
  statsFormatters[name] || defaultStatFormatter;

export const getRevenueLabel = (isFutureDate) =>
  isFutureDate
    ? `${t('Estimated')} ${t("Owner's").toLowerCase()} ${t('revenue')}`
    : `${t("Owner's")} ${t('revenue')}`;

export const getHostName = () => {
  const host = window.location.hostname;

  return host === 'localhost' && process.env.REACT_APP_HOSTNAME
    ? process.env.REACT_APP_HOSTNAME
    : host;
};

// TODO: extreme hack, remove when possible
export const isProduction = () =>
  process.env.REACT_APP_BASE_ORIGIN.endsWith('app.guesty.com');

export const getApiKey = () =>
  isProduction()
    ? 'KdDnXIZcVp0HBF2pzzVDHvNqE0CwxVnp'
    : 'wqkKdzmkvYBTwzApfxfRyQsj0sqPETSI';
