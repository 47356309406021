import t from '@guestyci/localize/t.macro';

import { USE_ACCOUNTING_FLOW } from 'constants/featureToggle';

export default [
  {
    getName: () => t('My properties'),
    href: 'my-properties',
    toShow: () => true,
  },
  {
    getName: () => t('Reservation report'),
    href: 'reservation-report',
    toShow: ({ ownersPortalSettings }) => ownersPortalSettings.guestsReports,
  },
  {
    getName: () => t('Analytics'),
    href: 'analytics',
    toShow: ({
      ownersPortalSettings: {
        avgNightlyRate,
        bookedNights,
        occupancy,
        revenue,
        revPal,
      },
    }) => avgNightlyRate || bookedNights || revenue || occupancy || revPal,
  },
  {
    getName: () => t('Statements'),
    href: 'statements',
    toShow: () => window.featureToggle?.isEnabled(USE_ACCOUNTING_FLOW), // && ownersPortalSettings.showOwnerStatements,
  },
  {
    getName: () => t('Documents'),
    href: 'documents',
    toShow: () => true,
  },
  {
    getName: () => t('Help center'),
    href: 'help-center',
    toShow: ({ ownersPortalSettings }) => ownersPortalSettings.showHelpCenter,
  },
];
