/* eslint-disable import/no-import-module-exports */
import { createRoot } from 'react-dom/client';

import Root from 'Root';

import './sass/index.scss';

const render = async () => {
  const root = createRoot(document.getElementById('root'));
  root.render(<Root />);
};

render();

if (navigator && 'serviceWorker' in navigator) {
  // Remove any service workers that were previously registered
  navigator.serviceWorker
    .getRegistrations()
    .then((registrations) => registrations.forEach((r) => r.unregister()));
}

if (module.hot) {
  module.hot.accept('./Root', render);
}
