import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import qs from 'query-string';
import set from 'lodash/set';

import createHistory from '@guestyci/history/createHistory';

import { get } from 'api/http-client';
import App from 'App.container';
import {
  getUser,
  loginAsOwner,
  goToRoot,
  validateHash,
} from 'user/user.actions';
import {
  getOwnersPortalMeta,
  setCurrentLocaleAction,
  uiAction,
} from 'ui/ui.actions';
import defaultCountryCodes from 'constants/defaultCountryCodes';
import { initMoment } from 'init';
import { setIdentity } from 'utils/dio';
import { getHostName } from 'utils/common';
import localStorage from 'utils/localStorage';
import jwt from 'utils/jwt';
import configureStore from 'store';

import Error404 from './components/Error404';

// TODO: restructure data-flow;
// TODO: switch between screens via router & fetch initial data accordingly;
// TODO: remove direct using of store;

const registrationHashRegex = /[?&]hash=([^?&]*)/g;
const isURLContainsHash = registrationHashRegex.exec(window.location.search);
const history = createHistory();
const store = configureStore();

const getInitialState = () => {
  const path = window.location.pathname;

  if (path.indexOf('register') !== -1) {
    return 'REGISTER';
  }
  if (path.indexOf('reset') !== -1) {
    return 'FORGOT_PASSWORD';
  }
  return 'LOGIN';
};

const getInitialAppConfig = () => {
  const state = getInitialState();
  const host = getHostName();

  store.dispatch(getOwnersPortalMeta(host));

  switch (state) {
    case 'LOGIN':
      if (isURLContainsHash) {
        const userToken = isURLContainsHash.length ? isURLContainsHash[1] : '';
        store.dispatch(loginAsOwner(userToken));
      }

      return Promise.resolve({
        initState: state,
        email: '',
        name: '',
        locale: 'en-US',
      });

    default:
      if (isURLContainsHash) {
        const hash = isURLContainsHash.length ? isURLContainsHash[1] : '';
        // Query mailer for site data
        return store.dispatch(validateHash(hash));
      }

      // Forgot password and register routes are not valid without a hash
      store.dispatch(goToRoot());
      return Promise.reject(Error('Invalid hash'));
  }
};

const normalizeLanguageCode = (languageCode) => {
  if (!defaultCountryCodes[languageCode.split('-')[0]]) {
    return null;
  }

  return languageCode.includes('-')
    ? languageCode
    : `${languageCode}-${defaultCountryCodes[languageCode]}`;
};

const getAccountId = async () => {
  const { accountId } = jwt.decode(localStorage.getAuthToken());
  if (accountId) {
    return accountId;
  }

  const response = await get(`/v2/owners-portal/meta/${getHostName()}`);
  return response.accountId._id;
};

class Root extends React.Component {
  constructor(props) {
    super(props);

    // initial state from appConfig
    getInitialAppConfig()
      .then((appConfig) =>
        store.dispatch(
          uiAction({ ...appConfig, ...qs.parse(window.location.search) })
        )
      )
      .then(() => store.dispatch(getUser()))
      .catch((err) => {
        console.log('Error during the app initialization: ', err);
      })
      .finally(async () => {
        // if user is already authenticated (token is in query string), init with his saved language,
        // else with browser language
        const { user } = store.getState();
        const { locale: defaultLanguage } = store.getState().ui;
        const relevantBrowserLanguages = [
          ...new Set(
            navigator.languages
              .map((lang) => normalizeLanguageCode(lang))
              .filter((lang) => lang)
          ),
        ];

        const userLocale =
          user.locale || relevantBrowserLanguages[0] || defaultLanguage;

        store.dispatch(setCurrentLocaleAction(userLocale));
        localStorage.setLanguage(userLocale);
        initMoment(userLocale);

        if (user) {
          setIdentity(user);
        }

        if (process.env.NODE_ENV === 'development') {
          set(window, 'appConfig.accountId', await getAccountId());
        }
      });
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route exact path="/404" component={Error404} />
            <Route path="/" render={() => <App history={history} />} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default Root;
