/* eslint-disable no-param-reassign */
import { createApi } from '@reduxjs/toolkit/query/react';

import Resource from '@guestyci/agni';

let cachedApi = null;

const dynamicBaseQuery = async (args, api, extraOptions) => {
  if (!cachedApi) {
    cachedApi = Resource.create({
      domain: 'v2/listings',
      development: true,
    }).api;
  }

  return cachedApi(args, api, extraOptions);
};

const listingsFields = [
  '_id',
  'nickname',
  'address.full',
  'picture.thumbnail',
  'complexId',
  'mtl',
  'bedrooms',
  'active',
];

export const listingsApi = createApi({
  reducerPath: 'listingsApi',
  baseQuery: dynamicBaseQuery,
  endpoints: ({ query }) => ({
    getListings: query({
      query: (params) => ({
        url: '',
        params: {
          ...params,
          fields: listingsFields.join(' '),
          pagination: true,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (newItems.skip === 0) {
          currentCache.results = newItems.results;
        } else {
          currentCache.results.push(...newItems.results);
        }
        currentCache.skip = newItems.skip;
        currentCache.limit = newItems.limit;
        currentCache.count = newItems.count;
        currentCache.sort = newItems.sort;
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
    }),

    getAllListings: query({
      queryFn: async (_arg, _api, _extraOptions, baseQuery) => {
        const listings = [];
        let skip = 0;
        let result;

        do {
          // eslint-disable-next-line no-await-in-loop
          result = await baseQuery({
            url: '',
            params: {
              fields: listingsFields.join(' '),
              skip,
              sort: 'nickname',
            },
          });

          listings.push(...result.data.results);
          skip += result.data.limit;
        } while (skip < result.data.count);

        return { data: listings };
      },
    }),
  }),
});

export const { useGetListingsQuery, useGetAllListingsQuery } = listingsApi;
