import { lazy } from 'react';
import { matchPath } from 'react-router-dom';

import Login from 'containers/Login.container';
import BookingRequest from 'containers/BookingRequest.container';
import ForgotPasswordRoute from 'containers/ForgotPasswordRoute.container';
import {
  USE_ACCOUNTING_FLOW,
  USE_RENTAL_PERIOD_REQUEST_TO_BOOK,
} from 'constants/featureToggle';

const AnalyticsPageWrapper = lazy(() => import('pages/AnalyticsPageWrapper'));
const DocumentsPageWrapper = lazy(() => import('pages/DocumentsPageWrapper'));
const MyProfilePageWrapper = lazy(() => import('pages/MyProfilePageWrapper'));
const MyPropertiesPageWrapper = lazy(
  () => import('pages/MyPropertiesPageWrapper')
);
const ReservationReportPageWrapper = lazy(
  () => import('pages/ReservationReportPageWrapper')
);
const StatementsPageWrapper = lazy(() => import('pages/StatementsPageWrapper'));
const HelpCenterPageWrapper = lazy(() => import('pages/HelpCenterPageWrapper'));

const login = {
  path: '/login',
  component: Login,
  isFetching: (state) => !state.ui.currentLocale,
  initState: 'LOGIN',
  isAvailable: () => true,
};

const register = {
  path: '/register',
  component: Login,
  isFetching: (state) => !state.ui.currentLocale,
  initState: 'REGISTER',
  isAvailable: () => true,
};

const password = {
  path: '/login/reset',
  component: Login,
  isFetching: (state) => !state.ui.currentLocale,
  initState: 'NEW_PASSWORD',
  isAvailable: () => true,
};

const forgotPassword = {
  path: '/forgot-password',
  component: ForgotPasswordRoute,
  isFetching: (state) => !state.ui.currentLocale,
  isAvailable: () => true,
};

const properties = {
  path: '/my-properties',
  component: MyPropertiesPageWrapper,
  isFetching: (state) => !state.ui.currentLocale || !state.user,
  isAvailable: () => true,
};

const profile = {
  path: '/my-profile',
  component: MyProfilePageWrapper,
  isFetching: (state) => !state.ui.currentLocale || !state.user,
  isAvailable: () => true,
};

const analytics = {
  path: '/analytics',
  component: AnalyticsPageWrapper,
  isFetching: (state) => !state.ui.currentLocale || !state.user,
  isAvailable: ({
    ownersPortalSettings: {
      avgNightlyRate,
      bookedNights,
      occupancy,
      revenue,
      revPal,
    },
  }) => avgNightlyRate || bookedNights || revenue || occupancy || revPal,
};

const statements = {
  path: '/statements',
  component: StatementsPageWrapper,
  isFetching: (state) => !state.ui.currentLocale || !state.user,
  isAvailable: () => window.featureToggle?.isEnabled(USE_ACCOUNTING_FLOW), // && ownersPortalSettings.showOwnerStatements,
};

const reservationReport = {
  path: '/reservation-report',
  component: ReservationReportPageWrapper,
  isFetching: (state) => !state.ui.currentLocale || !state.user,
  isAvailable: ({ ownersPortalSettings }) => ownersPortalSettings.guestsReports,
};

const bookingRequest = {
  path: '/request-to-book/:reservationId',
  component: BookingRequest,
  isFetching: (state) => !state.ui.currentLocale || !state.user,
  isAvailable: ({ ownersPortalSettings }) =>
    window.featureToggle?.isEnabled(USE_RENTAL_PERIOD_REQUEST_TO_BOOK) &&
    ownersPortalSettings.rentalPeriods?.requestToBook,
};

const documents = {
  path: '/documents',
  component: DocumentsPageWrapper,
  isFetching: (state) => !state.ui.currentLocale || !state.user,
  isAvailable: () => true,
};

const helpCenter = {
  path: '/help-center',
  component: HelpCenterPageWrapper,
  isFetching: (state) => !state.ui.currentLocale || !state.user,
  isAvailable: ({ ownersPortalSettings }) =>
    ownersPortalSettings.showHelpCenter,
};

const routes = [
  login,
  register,
  password,
  properties,
  profile,
  analytics,
  statements,
  forgotPassword,
  reservationReport,
  bookingRequest,
  documents,
  helpCenter,
];

export default routes;

export const getRoute = (pathname) => {
  for (let i = 0, ii = routes.length; i < ii; i += 1) {
    const route = routes[i];
    const match = matchPath(pathname, {
      path: routes[i].path,
      exact: true,
    });

    if (match) {
      return { ...route, match };
    }
  }
};
