import _get from 'lodash/get';

import { get, post } from 'api/http-client';
import localStorage from 'utils/localStorage';
import { OWN_HYDRA_AUTH } from 'constants/featureToggle';
import { initFeatureToggle } from 'init';
import { getHostName } from 'utils/common';
import jwt from 'utils/jwt';

// NOTE: temporary hack to switch between the old auth api vs new auth api
const shouldCallHydra = async () => {
  if (!window.featureToggle) {
    // we have to init featuretoggle here because we need to know if we should use owners hydra or not
    // remove this when we have a better solution, we still re-init featuretoggle when GET me is done
    await initFeatureToggle();
  }

  return !!window.featureToggle?.isEnabled(OWN_HYDRA_AUTH);
};

export const getUser = async () => {
  const token = localStorage.getAuthToken();

  return token ? get('/owners/me') : Promise.reject(Error('NO_TOKEN'));
};

const onSuccessReponse = (resp) => {
  if (resp.token) {
    localStorage.setAuthToken(resp.token);
  } else {
    console.log('no token was found for user');
  }
  return null;
};

export const login = async (username, password) => {
  if (await shouldCallHydra()) {
    return post('/owners/auth/login', {
      username,
      password,
      hostname: getHostName(),
    }).then(onSuccessReponse);
  }

  return post('/v2/authenticate', {
    username,
    password,
    accountId: _get(window, ['appConfig', 'accountId']),
  }).then(onSuccessReponse);
};

export const validateHash = async (hash) => {
  if (await shouldCallHydra()) {
    return get(`/owners/auth/${getHostName()}/${hash}`);
  }

  return get(`/v2/owners-portal/forgot-password/${getHostName()}/${hash}`);
};

export const forgotPassword = async (username) => {
  if (await shouldCallHydra()) {
    return post('/owners/auth/password/forgot', {
      username,
      hostname: getHostName(),
    }).then(() => null);
  }

  return post('/v2/authenticate/forgot-password', {
    username,
    accountId: _get(window, ['appConfig', 'accountId']),
  }).then(() => null);
};

export const resetPassword = async (hash, password) => {
  if (await shouldCallHydra()) {
    return post('/owners/auth/password', {
      hash,
      password,
      hostname: getHostName(),
    }).then(onSuccessReponse);
  }

  return post('/v2/authenticate/forgot-password/reset', {
    hash,
    password,
    accountId: _get(window, ['appConfig', 'accountId']),
  }).then(onSuccessReponse);
};

export const logout = async () => {
  if (await shouldCallHydra()) {
    return post('/owners/me/auth/logout', {}).finally(() => {
      localStorage.removeAuthToken();
      return null;
    });
  }

  return post('/v2/authenticate/revoke', {}).finally(() => {
    localStorage.removeAuthToken();
    return null;
  });
};

export const saveUserToken = (userToken) =>
  new Promise((resolve, reject) => {
    if (!userToken) {
      reject(Error('Have no token'));
    }

    localStorage.setAuthToken(userToken);
    const tokenFromStorage = localStorage.getAuthToken();

    if (tokenFromStorage) {
      resolve(tokenFromStorage);
    } else {
      reject(Error("USER_TOKEN_STORAGE_KEY wasn't saved"));
    }
  });

export const whoami = () => jwt.decode(localStorage.getAuthToken());
