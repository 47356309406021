import {
  withSegmentTracking,
  identifyUser,
  trackLogin,
  trackLogout,
} from 'utils/segment';
import {
  getUser as doGetUser,
  login as doLogin,
  validateHash as doValidateHash,
  forgotPassword as doForgotPassword,
  resetPassword as doResetPassword,
  logout as doLogout,
  saveUserToken,
} from 'user/user.services';
import { showLoader, hideLoader } from 'ui/ui.actions';
import tracker from 'utils/dio/auth-tracker';
import { initFeatureToggle } from 'init';

export const types = {
  USER: 'USER',
  PASSWORD_SENT: 'PASSWORD_SENT',
  PASSWORD_RESET: 'PASSWORD_RESET',
  LOGOUT: 'LOGOUT',
  USER_UPDATED: 'USER_UPDATED',
};

const userAction = (user) =>
  withSegmentTracking({ type: 'USER', user }, [
    identifyUser(user),
    trackLogin(user),
  ]);

const passwordSentAction = (err) => ({
  type: 'PASSWORD_SENT',
  err,
});

const passwordResetAction = (err) => ({
  type: 'PASSWORD_RESET',
  err,
});

const logoutAction = (err) =>
  withSegmentTracking({ type: 'LOGOUT', err }, [trackLogout]);

export const userUpdatedAction = (user, err) => ({
  type: 'USER_UPDATED',
  user,
  err,
});

export const goToRoot = () => () => {
  document.location.href = '/';
};

export const getUser = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(showLoader());

    doGetUser()
      .then((user) => initFeatureToggle().then(() => user))
      .then((user) => dispatch(userAction(user)))
      .then(() => resolve())
      .catch(() => {
        dispatch(userAction({ authenticated: false }));
        reject(Error({ authenticated: false }));
      })
      .finally(() => dispatch(hideLoader()));
  });

export const login = (username, password) => (dispatch) =>
  doLogin(username, password)
    .then(doGetUser)
    .then((user) => {
      tracker.onLoginSuccess(user);
      return initFeatureToggle().then(() => user);
    })
    .then((user) => dispatch(userAction(user)))
    .catch(() =>
      dispatch(userAction({ username, authenticated: false, loginError: true }))
    );

export const register = (hash, username, password) => (dispatch) =>
  doResetPassword(hash, password)
    .then(() => dispatch(login(username, password)))
    .then(() => dispatch(goToRoot()))
    .catch(() =>
      dispatch(userAction({ authenticated: false, loginError: true }))
    );

export const forgotPassword = (username) => (dispatch) =>
  doForgotPassword(username)
    .then(() => dispatch(passwordSentAction()))
    .catch((err) => dispatch(passwordSentAction(err)));

export const resetPassword = (hash, username, password) => (dispatch) =>
  doResetPassword(hash, password)
    .then(() => dispatch(passwordResetAction()))
    .then(() => dispatch(login(username, password)))
    .then(() => dispatch(goToRoot()))
    .catch((err) => dispatch(passwordResetAction(err)));

export const logout = () => (dispatch) =>
  doLogout()
    .then(() => {
      tracker.onLogout();
      return dispatch(logoutAction());
    })
    .catch((err) => dispatch(logoutAction(err)));

export const loginAsOwner = (userToken) => (dispatch) =>
  saveUserToken(userToken)
    .then(() => dispatch(goToRoot()))
    .catch(() =>
      dispatch(userAction({ authenticated: false, loginError: true }))
    );

export const validateHash = (hash) => (dispatch) =>
  doValidateHash(hash).catch(() => {
    dispatch(goToRoot());
  });
