export const getCombinedOwnersPortalSettings = (owner, portal) => {
  const ownersPortalSettings = { ...(owner.ownersPortalSettings || {}) };

  if (ownersPortalSettings.allowReservations === undefined) {
    ownersPortalSettings.allowReservations = owner.allowReservations;
  }

  if (ownersPortalSettings.allowReservationsUnavailableDates === undefined) {
    ownersPortalSettings.allowReservationsUnavailableDates =
      owner.allowReservationsUnavailableDates;
  }

  if (ownersPortalSettings.showGuestFullName === undefined) {
    ownersPortalSettings.showGuestFullName =
      portal?.defaultOwnersPortalSettings?.showGuestFullName ??
      (portal?.showReservationTooltipFullName ||
        portal?.showGuestReportFullName) ??
      true;
  }

  if (ownersPortalSettings.showGuestEmail === undefined) {
    ownersPortalSettings.showGuestEmail =
      portal?.defaultOwnersPortalSettings?.showGuestEmail ??
      portal?.showGuestReportEmail ??
      true;
  }

  if (ownersPortalSettings.showGuestPhone === undefined) {
    ownersPortalSettings.showGuestPhone =
      portal?.defaultOwnersPortalSettings?.showGuestPhone ??
      portal?.showGuestReportPhone ??
      true;
  }

  // temporary default fallbacks
  if (ownersPortalSettings.showReservedReservations === undefined) {
    ownersPortalSettings.showReservedReservations = true;
  }

  // if (ownersPortalSettings.showOwnerStatements === undefined) {
  //   ownersPortalSettings.showOwnerStatements = true;
  // }

  if (ownersPortalSettings.bookingSource === undefined) {
    ownersPortalSettings.bookingSource = true;
  }

  return ownersPortalSettings;
};
