import { getCombinedOwnersPortalSettings } from 'utils/owner';

export const getUser = (state) => state.user;

export const isPasswordReseted = (state) => !!state.user.passwordReset;

export const getUserLanguage = (state) => state.user.locale || 'en-US';

export const getAccountCurrency = (state) => state.user.account?.currency;

export const getOwnersPortalSettings = (state) =>
  getCombinedOwnersPortalSettings(state.user, state.ui.meta);
